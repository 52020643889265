import { useTheme } from "@mui/material/styles";
import L, { LeafletMouseEvent } from "leaflet";
import * as React from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import "../leaflet-darkmode.css";
import { VirtualStopMarkerProps } from "../types/customProps";
import { isStopSuspended } from "../utils/stopUtils";

const createMarkerIcon = (color: string) =>
  new L.Icon({
    iconUrl: require(`../img/icon_default_${color}.png`),
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
    tooltipAnchor: [12, -21],
  });

const VirtualStopMarker: React.FC<VirtualStopMarkerProps> = ({
  position,
  nr,
  label,
  suspendedFrom,
  suspendedTo,
  setSelectedMarkerPosition,
  setSelectedLocation,
}) => {
  const theme = useTheme();

  const markerIcon = React.useMemo(() => {
    let iconColor = "green";
    const suspensionStatus = suspendedFrom && isStopSuspended(suspendedFrom);
    if (suspensionStatus === "aktuell") {
      iconColor = "error";
    } else if (suspensionStatus === "zukünftig") {
      iconColor = "warning";
    }
    return createMarkerIcon(iconColor);
  }, [suspendedFrom]);

  const handleMouseOver = (event: LeafletMouseEvent) =>
    event.target.openPopup();
  const handleMouseOut = (event: LeafletMouseEvent) =>
    event.target.closePopup();
  const handleClick = (event: LeafletMouseEvent) => {
    if (setSelectedMarkerPosition && setSelectedLocation) {
      setSelectedLocation({
        id: null,
        label: `${nr} ${label}`,
        lat: position.lat,
        lon: position.lng,
        suspendedFrom: suspendedFrom,
        suspendedTo: suspendedTo,
        pointType: "V",
      });
      setSelectedMarkerPosition(event.latlng);
    }
  };

  return (
    position && (
      <Marker
        position={[position.lat, position.lng]}
        icon={markerIcon}
        eventHandlers={{
          mouseover: handleMouseOver,
          mouseout: handleMouseOut,
          click: handleClick,
        }}
      >
        <Popup
          closeButton={false}
          className={
            theme.palette.mode === "dark" ? "dark-popup" : "light-popup"
          }
        >
          <b>{label}</b>
        </Popup>
        <Tooltip
          direction="right"
          permanent
          className={
            theme.palette.mode === "dark" ? "dark-tooltip" : "light-tooltip"
          }
        >
          {nr}
        </Tooltip>
      </Marker>
    )
  );
};

export default React.memo(VirtualStopMarker);
