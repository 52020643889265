import { Theme, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { CountdownProps } from "../types/customProps";
import { formatCountdown } from "../utils/dateUtils";

const Countdown: React.FC<CountdownProps> = ({ timeLeft, setTimeLeft }) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Zählt den Timer herunter
  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          return 0;
        }
      });
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [setTimeLeft]);

  const textColor = useMemo(() => {
    if (timeLeft < 10) {
      return timeLeft === 0
        ? (theme: Theme) => theme.palette.error.main
        : (theme: Theme) => theme.palette.warning.main;
    }
    return "inherit";
  }, [timeLeft]);

  return (
    <Typography
      fontSize="53px"
      component="h1"
      variant="h3"
      sx={{ color: textColor }}
    >
      {formatCountdown(timeLeft)}
    </Typography>
  );
};

export default Countdown;
