import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import { CancelTableProps } from "../types/customProps";
import { formatDateTime } from "../utils/dateUtils";

const CancelTable: React.FC<CancelTableProps> = ({ time, by, cause }) => {
  const theme = useTheme();

  const headerStyle = useMemo(
    () => ({
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.error.dark
          : theme.palette.error.light,
    }),
    [theme.palette.mode, theme.palette.error.dark, theme.palette.error.light]
  );

  const rowStyle = useMemo(
    () => ({
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[900],
    }),
    [theme.palette.mode, theme.palette.grey]
  );

  return (
    <TableContainer>
      <Table sx={{ maxWidth: "50%", my: 2 }} size="small">
        <TableHead>
          <TableRow sx={headerStyle}>
            <TableCell align="center">Stornierungszeit</TableCell>
            <TableCell align="center">Storniert durch</TableCell>
            <TableCell align="center">Grund</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={rowStyle}>
            <TableCell component="th" scope="row" align="center">
              {formatDateTime(time)}
            </TableCell>
            <TableCell align="center">{by}</TableCell>
            <TableCell align="center">{cause}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CancelTable;
