export interface AutocompleteOption {
  id: string | null;
  label: string;
  lat: number;
  lon: number;
  suspendedFrom: string | null;
  suspendedTo: string | null;
  pointType: string;
}

export interface MarkerPosition {
  lat: number;
  lng: number;
}

export enum RequestType {
  ARRIVAL = "ARRIVAL",
  DEPARTURE = "DEPARTURE",
}

export interface bookingRequestData {
  requestDate: {
    requestTime: string; // Format: $date-time
    requestType: RequestType;
  };
  origin: {
    name: string;
    lat: number;
    lon: number;
  };
  destination: {
    name: string;
    lat: number;
    lon: number;
  };
  requestOptions: {
    passenger: number; // Min: 1, Max: 7
    stroller?: number | null; // Min: 0, Max: 2
    wheelchair?: number | null; // Min: 0, Max: 2
    babySeat?: number | null; // Min: 0, Max: 2
    childSeat?: number | null; // Min: 0, Max: 2
    bike?: number | null; // Min: 0, Max: 2
  };
  userMetaData?: {
    userPlatform: "CALLCENTER";
    displayName?: string; // Default: "Nicht angegeben"
    phoneNumber?: string; // Default: "Nicht angegeben"
  };
}

export interface maxNumbersPassengerInfo {
  maxNumberPassengers: number;
  maxNumberWheelchairs: number;
  maxNumberBabyseats: number;
  maxNumberChildseats: number;
  maxNumberStrollers: number;
}

export interface DetailsTableData {
  requestId: string;
  rejectionReason: string | null;
  rejectionReasonString: string | null;
  itineraries: Array<ItineraryData>;
}

export interface ItineraryData {
  itineraryId: string;
  startDate: string;
  duration: number; // Sekunden
  startTimeHHMM: string;
  endTimeHHMM: string;
  transfers: number;
  legs: Array<ItineraryLeg>;
}

export interface ItineraryLeg {
  startTimeHHMM: string;
  endTimeHHMM: string;
  distance: number;
  duration: number; // Sekunden
  mode:
    | "WAIT"
    | "WALK"
    | "TRANSIT"
    | "BUS"
    | "TRAM"
    | "TRAIN"
    | "SUBURB"
    | "BIKE"
    | "BIKERENTAL"
    | "CAR"
    | "CARRENTAL"
    | "FLEXA"
    | "MOVEMIXSHUTTLE";
  route: string | null;
  routeColor: string | null;
  from: string;
  to: string;
  headsign: string | null;
  passengers: number;
  wheelchairs: number;
  strollers: number;
  childSeats: number;
  babySeats: number;
  flexaName: string | null;
  departureLowerBound: string | null;
  departureUpperBound: string | null;
  arrivalLowerBound: string | null;
  arrivalUpperBound: string | null;
}

export interface Booking {
  requestId: string;
  itineraryId: string;
  userName: string;
  phoneNr: string;
  time: string;
  originName: string;
  destName: string;
  flexaName: string;
  legs: Array<ItineraryLeg>;
  bookedBy: string;
  state: string;
  cancelTime?: string | null;
  cancelledBy?: string | null;
  cancelCause?: string | null;
}
export interface BookingSortable {
  userName: string;
  phoneNr: string;
  time: string;
  originName: string;
  destName: string;
  flexaName: string;
  bookedBy: string;
  state: string;
}

export interface HeadCell {
  id: keyof BookingSortable;
  label: string;
  icon: React.ReactNode;
}

export interface UserAuthenticateDto {
  userName: string;
  password: string;
}

export interface AuthContextType {
  token: string | null;
  exp: number;
  login: (username: string, password: string) => Promise<boolean>;
  logout: () => void;
}

export interface StateLabelConfig {
  color: string;
  icon: JSX.Element | null;
}
