import { Skeleton, Stack } from "@mui/material";

// Skeleton-Komponente die angezeigt werden soll, wenn eine Tabelle auf Daten wartet
function TableSkeleton() {
  return (
    <Stack sx={{ width: "100%", alignItems: "center" }}>
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={50}
        sx={{ mb: 2, borderRadius: 1 }}
      />
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={50}
        sx={{ mb: 2, borderRadius: 1 }}
      />
      <Skeleton
        variant="rectangular"
        width={"100%"}
        height={50}
        sx={{ mb: 2, borderRadius: 1 }}
      />
    </Stack>
  );
}

export default TableSkeleton;
