import axios from "axios";

const AUTOCOMPLETE_URL = process.env.REACT_APP_AUTOCOMPLETE_URL;
const AUTOCOMPLETE_API_KEY = process.env.REACT_APP_AUTOCOMPLETE_API_KEY;
const CITY = process.env.REACT_APP_CITY

export async function getAutocomplete(search: string) {
  const params = {
    headers: {
      accept: "application/json",
      "X-API-Key": AUTOCOMPLETE_API_KEY,
    },
    params: {
      search: search,
      center: "51.3714,12.3720",
      profile: CITY === "halle" ? "movemix" : "flexa",
    },
  };
  const response = await axios.get(AUTOCOMPLETE_URL + "/search/", params);

  return response.data;
}
