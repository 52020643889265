import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { SortableTableProps } from "../types/customProps";
import { BookingSortable } from "../types/customTypes";

function SortableTableHead(props: SortableTableProps) {
  const { order, orderBy, onRequestSort, headCells, addEndPlaceholder } = props;

  const createSortHandler =
    (property: keyof BookingSortable) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[300] : null,
          }}
        >
          {/*Platzhalter für Collapse-Icon*/}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            align="center"
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light" ? theme.palette.grey[300] : null,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography
                fontSize="18px"
                fontWeight="bold"
                gap={"5px"}
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  marginLeft: "20px",
                }}
              >
                {headCell.icon}
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {addEndPlaceholder ? (
          <TableCell
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light" ? theme.palette.grey[300] : null,
            }}
          >
            {/*Platzhalter für Storno-Button*/}
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
}

export default SortableTableHead;
