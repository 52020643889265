import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { CustomSelectMenuProps } from "../types/customProps";

const CustomSelectMenu: React.FC<CustomSelectMenuProps> = ({
  searchColumns,
  setSearchColumns,
  columns,
}) => {
  const [searchSelectLabel, setSearchSelectLabel] =
    useState<string>("Alle Spalten");

  const handleChangeSearchColumns = (
    event: SelectChangeEvent<typeof searchColumns>
  ) => {
    const {
      target: { value },
    } = event;
    setSearchColumns(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearchSelectOpen = () => {
    setSearchSelectLabel("Suche in...");
  };

  const handleSearchSelectClose = () => {
    if (searchColumns.length === 0) {
      setSearchSelectLabel("Alle Spalten");
    }
  };

  const handleClearSelection = () => {
    setSearchColumns([]);
    setSearchSelectLabel("Alle Spalten");
  };

  return (
    <FormControl sx={{ width: "100%", mt: 0.5, ml: 1 }}>
      <InputLabel id="demo-multiple-chip-label">{searchSelectLabel}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={searchColumns}
        onChange={handleChangeSearchColumns}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            label={searchSelectLabel}
            endAdornment={
              searchColumns.length > 0 && (
                <InputAdornment position="end" sx={{ mr: 2 }}>
                  <IconButton
                    aria-label="clear selection"
                    onClick={handleClearSelection}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        }
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              m: -0.6,
            }}
          >
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        onOpen={handleSearchSelectOpen}
        onClose={handleSearchSelectClose}
      >
        {columns.map((head) => (
          <MenuItem key={head} value={head}>
            {head}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectMenu;
