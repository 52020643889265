import axios from "axios";

const NOMINATIM_URL = "https://mobifabrik.l.de:3000/nominatim/reverse";

export async function getGeoJSONFromCoords(lat: number, lon: number) {
  const response = await axios.get(
    NOMINATIM_URL + `?format=geojson&lat=${lat}&lon=${lon}`
  );

  return response.data;
}
