import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticateUser } from "../api/dbServicesApi";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [credentialsAreInvalid, setCredentialsAreInvalid] =
    React.useState(false);

  const login = async (userName: string, password: string) => {
    try {
      const response = await authenticateUser({ userName, password });
      localStorage.setItem("authToken", response.token);
      const origin = location.state?.from?.pathname || "/map";
      navigate(origin);
      return true;
    } catch {
      return false;
    }
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const loginSuccess = await login(username, password);
    if (!loginSuccess) {
      setCredentialsAreInvalid(true);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 25,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Anmeldung erforderlich
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
          <TextField
            margin="normal"
            fullWidth
            id="userName"
            label="Nutzername"
            name="userName"
            error={credentialsAreInvalid}
            autoFocus
            onChange={(e) => {
              setUsername(e.target.value);
              setCredentialsAreInvalid(false);
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            error={credentialsAreInvalid}
            onChange={(e) => {
              setPassword(e.target.value);
              setCredentialsAreInvalid(false);
            }}
          />
          <Button
            fullWidth
            disabled={!(username && password)}
            variant="contained"
            color="secondary"
            type="submit"
            sx={{
              mt: 3,
              mb: 2,
            }}
          >
            LOGIN
          </Button>
          {credentialsAreInvalid && (
            <Typography
              variant="body2"
              color={(theme) => theme.palette.error.main}
            >
              Nutzername oder Passwort falsch!
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
