import { Box, Container, Toolbar } from "@mui/material";

// Start- und Fallback-Seite bei nicht existierender URL
function ManualPage() {
  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
        }}
      >
        <Toolbar />
        <Container
          maxWidth={false}
          sx={{
            mt: 3,
            mb: 1,
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 100px)",
            overflow: "auto",
          }}
        >
          <object
            data={require("../assets/movemix_shuttle_Schulung_1.4.pdf")}
            type="application/pdf"
            width="100%"
            height="100%"
          >
            <p>Schulungs-PDF für Callcenter-Mitarbeiter</p>
          </object>
        </Container>
      </Box>
    </>
  );
}

export default ManualPage;
