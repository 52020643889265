import axios from "axios";
import { bookingRequestData } from "../types/customTypes";

const BOOKING_MANAGER_URL = process.env.REACT_APP_BOOKING_MANAGER_URL;
const BOOKING_MANAGER_TOKEN = process.env.REACT_APP_BOOKING_MANAGER_TOKEN;

const client = axios.create({
  baseURL: BOOKING_MANAGER_URL,
  headers: {
    "Content-Type": "application/json",
    Authentication: BOOKING_MANAGER_TOKEN,
  },
});

export async function getRequests(
  state: string,
  limit: number,
  offset: number,
  lowerBound: string,
  upperBound: string
) {
  const response = await client.get("/v2/requests/static", {
    params: {
      state,
      limit,
      offset,
      lowerBound,
      upperBound,
    },
  });

  return response.data;
}

export async function sendBookingRequest(data: bookingRequestData) {
  const response = await client.post("/v2/requests/static", data);

  return response.data;
}

export async function cancelBookingRequest(
  requestId: string,
  itineraryId: string
) {
  const data = {
    cause: "USER_CANCELLED",
    by: "CALLCENTER",
  };

  const response = await client.put(
    "/v2/requests/static/" +
      requestId +
      "/itineraries/" +
      itineraryId +
      "/cancel",
    data
  );

  return response.data;
}
export async function acceptBookingRequest(
  requestId: string,
  itineraryId: string
) {
  const response = await client.put(
    "/v2/requests/static/" +
      requestId +
      "/itineraries/" +
      itineraryId +
      "/accept",
    {}
  );

  return response.data;
}
