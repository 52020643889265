import { formatDate } from "./dateUtils";

export const isStopSuspended = (suspendedFrom: string | null) => {
  if (!suspendedFrom) return false;
  const nowDate = new Date();
  const suspendedFromDate = new Date(suspendedFrom);
  return nowDate > suspendedFromDate ? "aktuell" : "zukünftig";
};

export const getSuspendedWarning = (
  suspendedFrom: string | null,
  suspendedTo: string | null
) => {
  if (!suspendedFrom) return "";
  if (!suspendedTo)
    return (
      "Diese Haltestelle wird vom " +
      formatDate(suspendedFrom) +
      " bis auf Weiteres nicht bedient."
    );
  return (
    "Diese Haltestelle wird vom " +
    formatDate(suspendedFrom) +
    " bis zum " +
    formatDate(suspendedTo) +
    " nicht bedient."
  );
};

export const getShortAdress = (properties: any) => {
  let name = properties.name || "";
  if (name !== "") name += ", ";
  let addr = properties.address;
  let road = addr.road || "";
  let hn = addr.house_number || "";
  if (name === road + ", ") name = "";
  if (hn !== "") hn = " " + hn + ", ";
  else if (road !== "") road += ", ";
  let suburb = addr.suburb || "";
  if (suburb !== "") suburb += ", ";
  let city = addr.city || "";
  return name + road + hn + suburb + city;
};
