import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavItemProps } from "../types/customProps";

const NavItem: React.FC<NavItemProps> = ({
  to,
  defaultIcon,
  activeIcon,
  text,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <ListItemButton
      component={Link}
      to={to}
      sx={{
        mb: 0.5,
        backgroundColor: isActive
          ? (theme) =>
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : theme.palette.grey[200]
          : "transparent",
      }}
    >
      <ListItemIcon>{isActive ? activeIcon : defaultIcon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

export default NavItem;
