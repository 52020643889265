import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, useTheme } from "@mui/material";
import React from "react";
import { ItineraryLeg, StateLabelConfig } from "../types/customTypes";
import { convertToLowerCaseExceptFirst, getCarStrings } from "./bookingUtils";
import { formatISODateTime } from "./dateUtils";

const carIdentifier = getCarStrings().carIdentifier;

export const ArrivalTime: React.FC<{ leg: ItineraryLeg }> = ({ leg }) => {
  if (
    leg.mode === carIdentifier &&
    leg.arrivalUpperBound &&
    leg.arrivalLowerBound
  ) {
    return (
      <>
        Ankunft: {formatISODateTime(leg.arrivalLowerBound)} -{" "}
        {formatISODateTime(leg.arrivalUpperBound)}
        <br />
      </>
    );
  }
  return null;
};

export const DepartureTimeWindow: React.FC<{ leg: ItineraryLeg }> = ({
  leg,
}) => {
  if (
    leg.mode === carIdentifier &&
    leg.departureLowerBound &&
    leg.departureUpperBound
  ) {
    return (
      <>
        Abholung: <b>{formatISODateTime(leg.departureLowerBound)}</b> -{" "}
        {formatISODateTime(leg.departureUpperBound)}
        <br />
      </>
    );
  }
  return null;
};

export const FlexaName: React.FC<{ leg: ItineraryLeg }> = ({ leg }) => {
  if (leg.mode === carIdentifier) {
    if (leg.flexaName) {
      return (
        <>
          Autokennung: <b>{leg.flexaName}</b>
          <br />
        </>
      );
    } else {
      return (
        <>
          Autokennung unbekannt
          <br />
        </>
      );
    }
  }
  return null;
};

export const PassengerInfo: React.FC<{ leg: ItineraryLeg }> = ({ leg }) => {
  if (leg.mode === carIdentifier) {
    return (
      <>
        Personen: <b>{leg.passengers.toString()}</b>
        <br />
      </>
    );
  }
  return null;
};

export const MorePassengerInfo: React.FC<{ leg: ItineraryLeg }> = ({ leg }) => {
  if (leg.mode === carIdentifier) {
    let output = "";
    if (leg.wheelchairs !== 0) {
      output += "Rollstuhl: " + leg.wheelchairs.toString() + " ";
    }
    if (leg.strollers !== 0) {
      output += "Kinderw./Rollator: " + leg.strollers.toString() + " ";
    }
    if (leg.childSeats !== 0) {
      output += "Sitzerhöhung: " + leg.childSeats.toString() + " ";
    }
    if (leg.babySeats !== 0) {
      output += "Babyschale: " + leg.babySeats.toString() + " ";
    }

    return (
      <>
        {output.trim() !== "" && (
          <>
            {output}
            <br />
          </>
        )}
      </>
    );
  }

  return null;
};

export const PlatformLabel: React.FC<{ platform: string }> = ({ platform }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {platform === "ANDROID" ? (
        <AndroidIcon sx={{ mr: 1 }} />
      ) : platform === "IOS" ? (
        <AppleIcon sx={{ mr: 1 }} />
      ) : platform === "CALLCENTER" ? (
        <SupportAgentIcon sx={{ mr: 1 }} />
      ) : null}
      {convertToLowerCaseExceptFirst(platform)}
    </Box>
  );
};

export const StateLabel: React.FC<{ state: string }> = ({ state }) => {
  const theme = useTheme();

  const stateConfig: { [key: string]: StateLabelConfig } = {
    storniert: {
      color: theme.palette.error.main,
      icon: <CancelIcon sx={{ mr: 1 }} />,
    },
    abgeschlossen: {
      color: theme.palette.success.main,
      icon: <CheckCircleIcon sx={{ mr: 1 }} />,
    },
    begonnen: {
      color: theme.palette.warning.main,
      icon: <DirectionsCarIcon sx={{ mr: 1 }} />,
    },
    "noch nicht begonnen": {
      color: "inherit",
      icon: <HourglassTopIcon sx={{ mr: 1 }} />,
    },
  };

  const { color, icon } = stateConfig[state] || {
    color: "inherit",
    icon: null,
  };

  return (
    <Box
      display="flex"
      whiteSpace="nowrap"
      alignItems="center"
      justifyContent="center"
      style={{ color }}
    >
      {icon}
      {state}
    </Box>
  );
};
