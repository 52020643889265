import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LegsTableProps } from "../types/customProps";
import {
  getBrightnessFromHexColor,
  getLegMode,
  getNonFlexaInfos,
} from "../utils/bookingUtils";
import { formatTime } from "../utils/dateUtils";
import {
  ArrivalTime,
  DepartureTimeWindow,
  FlexaName,
  MorePassengerInfo,
  PassengerInfo,
} from "../utils/infoUtils";

const LegsTable: React.FC<LegsTableProps> = ({ data }) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, my: 2 }} size="small">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.background.default
                  : theme.palette.grey[300],
            }}
          >
            <TableCell align="center">Von</TableCell>
            <TableCell align="center">Ab</TableCell>
            <TableCell align="center">Nach</TableCell>
            <TableCell align="center">An</TableCell>
            <TableCell align="center">Dauer</TableCell>
            <TableCell align="center">Produkt</TableCell>
            <TableCell align="center">Informationen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                backgroundColor: (theme) =>
                  row.routeColor
                    ? "#" + row.routeColor
                    : theme.palette.mode === "dark"
                    ? theme.palette.grey[900]
                    : theme.palette.grey[100],
                color: row.routeColor
                  ? getBrightnessFromHexColor(row.routeColor) < 90 // sehr dunkler Hintergrund
                    ? "#fff"
                    : getBrightnessFromHexColor(row.routeColor) > 165 // sehr heller Hintergrund
                    ? "#000"
                    : "inherit"
                  : "inherit",
              }}
            >
              <TableCell
                component="th"
                scope="row"
                align="center"
                sx={{ color: "inherit" }}
              >
                {row.from}
              </TableCell>
              <TableCell align="center" sx={{ color: "inherit" }}>
                {row.startTimeHHMM}
              </TableCell>
              <TableCell align="center" sx={{ color: "inherit" }}>
                {row.to}
              </TableCell>
              <TableCell align="center" sx={{ color: "inherit" }}>
                {row.endTimeHHMM}
              </TableCell>
              <TableCell align="center" sx={{ color: "inherit" }}>
                {formatTime(row.duration)}
              </TableCell>
              <TableCell align="center" sx={{ color: "inherit" }}>
                {getLegMode(row.route, row.mode)}
              </TableCell>
              <TableCell align="center" sx={{ color: "inherit" }}>
                {getNonFlexaInfos(row)}
                <DepartureTimeWindow leg={row} />
                <ArrivalTime leg={row} />
                <FlexaName leg={row} />
                <PassengerInfo leg={row} />
                <MorePassengerInfo leg={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LegsTable;
