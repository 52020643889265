import { JwtPayload, jwtDecode } from "jwt-decode";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ChildrenProps } from "../types/customProps";

const ProtectedRoute: React.FC<ChildrenProps> = ({ children }) => {
  const existsValidToken = () => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken) {
      const decodedToken = jwtDecode<JwtPayload>(storedToken);
      if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const location = useLocation();

  // Zugriff auf geschützte Route nur dann, wenn token vorhanden und noch nicht expired ist
  if (!existsValidToken()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
