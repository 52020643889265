import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React from "react";
import { CustomNumberInputProps } from "../types/customProps";

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  label,
  value,
  setValue,
  maxValue,
}) => {
  const handleIncrement = () => {
    setValue((prevValue) =>
      prevValue ? (prevValue + 1 > maxValue ? maxValue : prevValue + 1) : 1
    );
  };

  const handleDecrement = () => {
    setValue((prevValue) => (prevValue ? prevValue - 1 : 0));
  };

  return (
    <TextField
      label={label}
      sx={{ width: "100%" }}
      value={value > 0 ? value : ""}
      InputLabelProps={{
        shrink: value > 0,
      }}
      onChange={(e) => {
        const input = e.target.value;
        const cleanedInput = input.replace(/\D/g, "");
        if (cleanedInput === "") {
          setValue(0);
        } else {
          if (parseInt(cleanedInput) > maxValue) {
            setValue(maxValue);
          } else {
            setValue(parseInt(cleanedInput));
          }
        }
      }}
      InputProps={{
        endAdornment: (
          <React.Fragment>
            <InputAdornment position="end">
              <IconButton onClick={handleDecrement}>
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
            <InputAdornment position="end">
              <IconButton onClick={handleIncrement}>
                <AddIcon />
              </IconButton>
            </InputAdornment>
          </React.Fragment>
        ),
      }}
    />
  );
};

export default CustomNumberInput;
